import type { Relation } from '~/models/Relation';

export type RelationState = {
    relation: Relation | null;
}

export const defaultStateData = (): RelationState => ({
    relation: null,
});

export const DefaultState = (): RelationState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
