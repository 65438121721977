import { SET_RELATION } from './-mutation-types';
import { useRelationsStore } from '~/stores/relations';
import ExtranetRelationService from '~/services/api/extranet/RelationService';
import RelationService from '~/services/api/RelationService';
import type { Relation } from '~/models/Relation';

const Actions = {
    async fetchExtranetRelation() {
        const relationsStore = useRelationsStore();
        const relationService = new ExtranetRelationService();
        const response = await relationService.fetchRelation();

        relationsStore[SET_RELATION](response);
    },
    async fetchRelation(relationId: number) {
        const relationsStore = useRelationsStore();
        const relationService = new RelationService();
        const response = await relationService.fetchRelation(relationId);

        relationsStore[SET_RELATION](response);
    },
    setRelation(relation: Relation) {
        const relationsStore = useRelationsStore();
        relationsStore[SET_RELATION](relation);
    },
};

export default Actions;
